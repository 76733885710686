html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.logo-img {
  height: 50px;
}

/* navbar css=========================== */
.navbar .nav-item {
  margin-right: 35px;
}

.navbar .nav-item .nav-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  position: relative;
  display: inline-block;
}

.nav-link::before {
  transform: scaleX(0);
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #9647fa 5.66%, #1875f8 48.9%, #1acc96 100%);

  bottom: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.nav-link:hover::before {
  transform: scaleX(1);
}

.nav-link.active::before {
  transform: scaleX(1);
}

.header-btn .start-btn {
  background: #d4eff9;
  border: 1.86px solid;

  border: 2px solid #1ac79d;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}

.header-btn .start-btn:hover {
  background: #b8f6e5;
}

.text-blue {
  font-family: "Comfortaa", cursive;
  color: #1976f9;
  font-weight: 700;
}

.position-relative,
.fixed-top {
  animation: fadeIn;
  transition: all 5s linear !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* navbar css=========================== */
/* first banner css============================ */
.first-banner {
  background: url("./Components/assets/bg-1.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
}

.banner1-content h1 {
  font-size: 48px;
  font-weight: 500;
  color: #000;
  line-height: 74px;
}

.banner1-content h1 span {
  font-family: "Comfortaa", cursive;
  color: #1976f9;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

/* .banner1-content h1 span::before {
  content: "";
  position: absolute;
  top: 0;
  background: url(./Components/assets/text-bg.png);
  width: 107%;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
} */
.banner1-content p {
  font-size: 20px;
  font-weight: 500;
  color: #4d4d4d;
}

.banner1-content .banner1-btn {
  display: flex;
  align-items: center;
  gap: 30px;
}

.banner1-content .appointment-btn {
  background: #1976f9;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  padding: 12px 30px;
}

.banner1-btn .works-btn {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 18px;
}

/* first banner css============================ */
/* second banner css============================ */
.second-banner {
  background: #095dd5;
  padding: 50px 0 100px 0;
}

.second-banner .banner2-content .story {
  text-transform: uppercase;
  color: #acc8f0;
  font-weight: 400;
  font-size: 16px;
}

.banner2-content h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
}

.banner2-content p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.banner2-content .read-more-btn {
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}

.banner2-content .read-more-btn:hover {
  background: #b8f6e5;
  color: black;
}

/* second banner css============================ */
/* third banner css============================= */
.third-banner {
  background: url(./Components/assets/bg-2.png);
  background-size: cover;
  padding: 50px 0;
  background-position: center;
  display: flex;
  align-items: center;
}

.banner3-content .resone {
  font-size: 16px;
  font-weight: 400;
  color: #818181;
  text-transform: uppercase;
}

.banner3-content h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 64px;
}

.company-info1 {
  border-radius: 12px;
  width: 420px;
  padding: 1px;
  background: linear-gradient(0deg, rgba(26, 200, 156, 1) 8%, rgba(9, 93, 213, 1) 100%);
  margin-bottom: 15px;
}

.banner3-content .company-info1 .info {
  padding: 20px;
  display: flex;
  align-items: center;
  background: #f4fcff;
  border-radius: 10px;
}

/* third banner css============================= */
/* fourth section=================================== */
.fourth .process {
  text-transform: uppercase;

  color: #818181;
  font-size: 16px;
  font-weight: 400;
}

.fourth h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 64px;
}

/* .start-map {
  display: flex;
  justify-content: space-around;
  position: relative;
} */
.start-map-content {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.start-map-content::before {
  content: "";
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background: url(./Components/assets/dotted-line.svg);
  background-size: cover;
  width: 70%;
  height: 1px;
  margin: 0 auto;
  z-index: -1;
}

.start-map-content h3 {
  font-size: 24px;
  font-weight: 600;
}

.start-map-content p {
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #4e4e4e;
}

/* fourth section=================================== */
/* fifth section=============================== */
.fifth {
  background: #f5f9ff;
  padding: 40px 0 60px 0;
}

.fifth .non-emergency {
  color: #818181;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
}

.fifth h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
}

.fifth .health {
  font-weight: 500;
  font-size: 20px;
}

.healthbox-border {
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(180deg, #095dd5 0%, #81f0d3 75%);
  margin-bottom: 12px;
}

.health-box {
  border-radius: 8px;
  padding: 10px 20px;
  background: linear-gradient(180deg, #d0e4ff 0%, #ffffff 100%);
  display: flex;
  align-items: center;
}

/* fifth section=============================== */

/* form section================================= */
.form {
  background: url(./Components/assets/form-bg.png);
  background-size: cover;
  padding: 40px 0;
}

.form .message {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.form h1 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.chat-box {
  background: #004dba;
  border: 1px solid #237eff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 40px;
  gap: 20px;
}

.chat-box p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  color: #fff;
}

.form label {
  color: #cde2ff;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}

.form form input {
  width: 100%;
  background: #ffffff;
  border: 2px solid #cecece;
  border-radius: 5px;
  outline: none;
  padding: 5px 15px;
  color: #242f40;
  font-size: 16px;
  font-weight: 500;
}

.form form textarea {
  width: 100%;
  background: #ffffff;
  border: 2px solid #cecece;
  border-radius: 5px;
  outline: none;
  padding: 5px 15px;
  color: #242f40;
  font-size: 16px;
  font-weight: 500;
  resize: none;
}

.form .submit-btn {
  background: #1ac89c;
  border-radius: 8px;
  padding: 10px 60px;
  color: #fff;
}

/* privacy css============================== */
.privacy {
  margin-top: 100px;
}

.privacy hr {
  height: 1px;
  background: linear-gradient(90deg, #9647fa 5.66%, #1875f8 48.9%, #1acc96 100%);
  margin-bottom: 20px;
}

.privacy .arrow-right {
  font-size: 26px;
  color: #dc392d;
  line-height: 20px;
}

.privacy-content .privacy-para {
  color: rgb(13, 27, 54);
  font-size: 18px;
  font-weight: 300;
}

.policy-heading {
  background: linear-gradient(157deg, #9647fa 15%, #1875f8 30%, #1acc96 75%),
    url(./Components/assets/provider.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.privacy-content h4 {
  font-size: 24px;
  color: #2751a3;
  font-weight: 700;
}

@media (max-width: 767px) {
  .privacy {
    margin-top: 80px;
  }

  .privacy-content .heading {
    font-size: 35px;
  }

  .privacy .arrow-right {
    font-size: 18px;
    color: #dc392d;
    line-height: 20px;
  }

  .privacy-content h4 {
    font-size: 14px;
  }

  .privacy-content .privacy-para {
    font-size: 11px;
  }
}

/* privacy css============================== */

/* About css==================================== */

.about {
  margin-top: 100px;
}

.about h1 {
  color: #1976f9;
  font-size: 40px;
  font-weight: 700;
}

.about-content {
  margin: 0 auto;
}

.about-para {
  color: rgb(13, 27, 54);
  font-size: 16px;
  font-weight: 300;
}

.provider-content {
  margin: 0 auto;
}

.about-banner {
  background: linear-gradient(157deg, #9647fa 15%, #1875f8 30%, #1acc96 75%),
    url(./Components/assets/provider.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.about-banner h1 {
  color: #fff;
  font-weight: 500;
  font-size: 50px;
}

.medical-info {
  border: 2px solid #1ac79d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 20px;
}

.provider {
  background: url("./Components/assets/bg-1.png");
  background-position: 100% 100%;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  padding: 50px 0;
}

@media (max-width: 1300px) {
  .about-para {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .about {
    margin-top: 50px;
  }

  .about-banner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .provider .row {
    flex-direction: column-reverse;
  }

  .about h1 {
    font-size: 30px;
  }

  .about-banner h1 {
    font-size: 26px;
  }
}

/* About Css=================================== */

/* form section================================= */
/* footer css=============================== */
footer {
  background: #0e2845;
  padding-top: 70px;
  padding-bottom: 120px;
}

footer .heading {
  color: #d7d7d7;
  font-size: 17px;
  font-weight: 600;
}

footer ul {
  margin-top: 80px;
}

footer .address li,
.address a {
  color: #c5cddd;
  font-size: 16px;
  font-weight: 400;
}

footer li a {
  font-size: 18px;
  font-weight: 300;
  color: #c5cddd;
}

footer li {
  margin-top: 15px;
}

footer a::before {
  transform: scaleX(0);
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #9647fa 5.66%, #1875f8 48.9%, #1acc96 100%);
  bottom: 0;
  left: 0;
  transition: transform 0.5s ease;
}

footer a:hover::before {
  transform: scaleX(1);
}

.sub-footer {
  background: #0e2845;
  border-top: 1px solid #3e4a67;
  padding: 12px;
}

.sub-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-footer .copyright p {
  font-weight: 400;
  font-size: 14px;
  color: #8a94ab;
}

footer a {
  position: relative;
  display: inline-block;
}

.sub-footer .links a {
  color: #8a94ab;
  font-size: 14px;
  font-weight: 400;
}

.sub-footer .links a {
  position: relative;
  display: inline-block;
}

.sub-footer .links a::before {
  transform: scaleX(0);
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #9647fa 5.66%, #1875f8 48.9%, #1acc96 100%);
  bottom: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.sub-footer .links a:hover::before {
  transform: scaleX(1);
}

/* footer css=============================== */

.text-green {
  color: #1ac89c;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  background: linear-gradient(90deg, #1875f8 48.9%, #1acc96 100%);
  height: 10px;
  border-top: 0 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.policy-heading h2 {
  font-size: 50px;
}

.privacy h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.privacy .privacy-para {
  overflow-wrap: break-word;
}

@media (max-width: 767px) {
  .logo-img {
    height: 40px;
  }

  .policy-heading h2 {
    font-size: 30px;
  }

  .privacy h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .first-banner {
    height: 100% !important;
    background-size: cover;
  }

  .banner1-content h1 {
    font-size: 30px;
    line-height: 45px;
  }

  .banner1-content .banner1-btn {
    flex-direction: column;
    align-items: flex-start;
  }

  .second-banner {
    padding: 50px 0;
  }

  .second-banner .row {
    flex-direction: column-reverse;
  }

  .banner3-content h1 {
    font-size: 26px;
  }

  .banner3-content .company-info1 {
    width: 100%;
  }

  .fifth h1 {
    font-size: 20px;
    line-height: 22px;
  }

  .chat-box {
    padding-left: 15px;
    gap: 8px;
  }

  .chat-box p {
    font-size: 14px;
  }

  footer ul {
    margin-top: 25px;
  }

  .sub-footer-content {
    flex-direction: column;
    justify-content: center;
  }

  .start-map-content::before {
    content: none;
  }

  .navbar-collapse {
    position: absolute;
    top: 0;
    height: 100vh;
    background: #98caf8;
    left: 0;
    padding: 20px;
  }

  .collapsing {
    height: 0 !important;
    transition: none !important;
  }

  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .about-banner h1 {
    font-size: 30px;
  }
  .appoint-img{
    display: none;
  }
}

.transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.appoint-form {
  display: flex;
}

.appoint-img {
  left: 0;
  top: 0;
  background: #f4fcff;
  border-radius: 5px 0px 0px 5px;
}

.appoint-fields {
  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
}

.appoint-fields h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #7a61fb;
}

.appoint-fields p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #243239;
}

.appoint-fields form .form-group {
  margin: 20px 0px;
}

.appoint-fields form .form-group .form-label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000;
}

.appoint-fields form .form-group input {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 15px;
  font-size: 15px;
}

/* .appoint-img img {
  width: 100%;
  height: 100%;
} */

.ReactModal__Overlay {
  z-index: 10000;
}

.ReactModal__Content {
  width: 80%;
  height: auto;
  margin: auto;
}

.css-13cymwt-control {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
}

.modal-buttons {
  text-align: end;
}

.btn-x {
  background: #ffffff;
  border: 1px solid #7a61fb;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  color: #7a61fb;
  padding: 11px 23px;
}

.btn-submit {
  margin-left: 20px;
  background: #7a61fb;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #ffffff;
  padding: 11px 23px;
}

.btn-submit:disabled {
  background: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

@media screen and (max-width: 480px) {
  .appoint-fields h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
  }
  .appoint-fields form .btn-x {
    padding: 6px 12px;
  }
  
  .appoint-fields form .btn-submit {
    padding: 6px 12px;
  }
}
